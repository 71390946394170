import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { CookieService } from "ngx-cookie-service";
import { Contract } from "../layout/header/header.classes";
import { PostItem } from "../layout/history/postItem";
import { HistoryItem } from "../layout/historyItem";
import { Menu } from "../layout/menu/menu";
import { Text } from "./texts/text";
import { TextsService } from "./texts/texts.service";

const httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

export enum ORDER_REQ_TYPES {
    TYPE_REQUISITION = 1,
    TYPE_ORDER = 2,
}

export interface HeaderResponse {
    texts: Text[];
    //event: string;
    //role: string;
    //evStatus: string;
    //evStatusColor: string;
    //closeTime: string;
    contract: Contract;
    language: number;
    userName: string;
    messagesTxt: string;
    newMessages: number;
    languages: any;
    history: HistoryItem[];
    //chat?: boolean;

    eventStatus: ResponseEventStatus;
    event?: string; //for events evaluation

    req_order: ResponseReqOrder;
}

export class ResponseEventStatus {
    event?: string;
    idEvent?: string;
    role?: string;
    evStatus?: string;
    evStatusColor?: string;
    closeTime?: Date;
    chat?: boolean;

    close?: boolean;
}

export class ResponseReqOrder {
    name: string;
    type: number;
}

export interface FooterResponse {
    texts: Text[];
    scenario: string;
}

interface Response {
    header: HeaderResponse;
    footer: FooterResponse;
    menu: Menu[];

    locale: string;
    timezone: string;
    timeoffset: number;
    decimals: number;

    // validationTexts: Text[];
    // commonTexts: Text[];

    // token: string;

    ciScheme: any;
    logout?: boolean;
    reload?: boolean;
    chat: boolean;
}

interface ResponseHistory {
    url: string;
    posts: any;
}

@Injectable({
    providedIn: "root",
})
export class PageService {
    //readonly pagesWithoutHeaderFooter = 'login|dashboard';
    //readonly pagesWithoutHeaderFooter = ['/homepages/login', '/homepages/logout', '/fe/bexio/new-user-type'];
    // readonly pagesWithoutHeaderFooter = ["/fe/bexio/new-user-type"];

    private textsService: TextsService;
    private pageUrl = "/fw/site/page";

    private cache$: Observable<Response>;

    constructor(private http: HttpClient, private cookieService: CookieService) {
        this.textsService = new TextsService();
    }

    removeCache() {
        this.cache$ = null;
    }

    public getPage(clearCache: boolean = false, code: string = null) {
        if (!this.cache$ || clearCache) {
            this.cache$ = this.requestGetPage(code).pipe(shareReplay(1));
        }
        return this.cache$;
    }

    public requestGetPage(code: string = null): Observable<Response> {
        let url = this.pageUrl;
        url += "?lastMenuUrl=" + sessionStorage.getItem("lastMenuUrl");
        if (code) {
            url += "&code=" + code;
        }
        return this.http.get<Response>(url).pipe(
            map((response) => {
                if (response) {
                    return this.filterPageResponse(response);
                }
            })
        );
    }

    private filterPageResponse(response: Response): Response {
        if (response.header) {
            //   let languages = response.header.languages;
            //   response.header.languages = Object.keys(languages).map(key => {
            //                                                                   let lang: Language = new Language();
            //                                                                   lang.key = Number(key);
            //                                                                     lang.value = languages[key];
            //                                                                   return lang;
            //                                                                 });

            if (response.header) {
                response.header.texts = this.textsService.extractTexts(response.header.texts);
            }
            if (response.footer) {
                response.footer.texts = this.textsService.extractTexts(response.footer.texts);
            }
        }
        // if (response.validationTexts) {
        //     response.validationTexts = this.textsService.extractTexts(response.validationTexts);
        // }
        // if (response.commonTexts) {
        //     response.commonTexts = this.textsService.extractTexts(response.commonTexts);
        // }
        return response;
    }

    public changeLanguage(languageId: number) {
        this.cache$ = null;
        return this.http.get<Response>("/fw/homepages/change-language?id=" + languageId, httpOptions);
    }

    public changeLanguageByLocale(locale: string) {
        this.cache$ = null;
        return this.http.get<Response>("/fw/homepages/change-language?locale=" + locale, httpOptions);
    }

    public chooseHistory(key: number) {
        let url = "/fw/site/history";
        if (typeof key != "undefined") {
            url += "?step=" + key;
        }
        return this.http.get<ResponseHistory>(url, httpOptions).pipe(
            map((response) => {
                if (response) {
                    let posts = response.posts;
                    response.posts = Object.keys(posts).map((key) => {
                        let post = new PostItem();
                        post.name = key;
                        post.value = posts[key];
                        return post;
                    });
                }
                return response;
            })
        );
    }

    public getHistoryItems(): Observable<HistoryItem[]> {
        return this.http.get<HistoryItem[]>("/fw/site/get-history-items");
    }

    public checkActivity() {
        //return this.http.get("/fw/site/check-activity");
        return this.http.post("/fw/api/gw/users-service/activity/", null);
    }

    public getEventStatus(): Observable<ResponseEventStatus> {
        return this.http.get<ResponseEventStatus>("/fw/site/get-event-status");
    }

    // public isPageWithoutHeaderFooter(windowLocation: string): boolean {
    //     // let re = /^\/fe\/login/;

    //     return this.pagesWithoutHeaderFooter.indexOf(window.location.pathname) != -1;

    //     // let re = new RegExp(`^\/fe\/*(${this.pagesWithoutHeaderFooter})`);
    //     // //let re = new RegExp(`^\/homepages\/*(${this.pagesWithoutHeaderFooter})`);
    //     // // console.log(re);
    //     // // console.log(re.exec(window.location.pathname));
    //     // return re.exec(window.location.pathname) !== null;
    // }
}
