import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { PrimeNGConfig } from "primeng/api";
import { urlPath } from "./common/helpers";
import { RoutingHelpers } from "./common/helpers/routing.helpers";
import { TranslationService } from "./common/i18n/translation/translation.service";
import { FooterComponent } from "./common/layout/footer/footer.component";
import { HeaderComponent } from "./common/layout/header/header.component";
import { Language } from "./common/layout/language";
import { LoaderService } from "./common/services/loader.service";
import { PageService, ResponseReqOrder } from "./common/services/page.service";
import { CommonTextsService } from "./common/services/texts/common-texts.service";
import { ValidationTextsService } from "./common/services/texts/validation-texts.service";
import { UpdateHeaderEventType, UpdateHeaderParams, UserSettingsService } from "./common/services/user-settings.service";
import { take } from "rxjs";

@Component({
    selector: "lib-frontend-base",
    templateUrl: "frontend-base.component.html",
    styles: [],
})
export class FrontendBaseComponent implements OnInit {
    title = "app";

    @ViewChild("header") header: HeaderComponent;
    @ViewChild("footer") footer: FooterComponent;

    loader: boolean = true;
    loaderTransparent: boolean;
    errorMsg: string;

    //withoutHeaderFooter: boolean;
    @Input() haveHeaderFooter: boolean = true;
    @Input() setTitle: boolean = true;

    idEvent: string;
    chat: boolean;

    previousUrl: string;

    favIcon: HTMLLinkElement = document.querySelector("#appIcon");

    constructor(
        private pageService: PageService,
        private router: Router,
        private validationTextsService: ValidationTextsService,
        private commonTextsService: CommonTextsService,
        public userSettingsService: UserSettingsService,
        public loaderService: LoaderService,
        private titleService: Title,
        private changeDetectorRef: ChangeDetectorRef,
        private config: PrimeNGConfig,
        private cookieService: CookieService,
        private translationService: TranslationService,
        private activatedRoute: ActivatedRoute
    ) {
        if (!navigator.cookieEnabled) {
            // The browser does not support or is blocking cookies from being set.
            //sessionStorage needs to have cookies enabled
            this.errorMsg = "Cookies are disabled in your browser! Please enable them and reload page!";
            return;
        }
        loaderService.loaderChanged$.subscribe((loader) => {
            this.loader = loader.enabled;
            this.loaderTransparent = loader.transparent;
            this.changeDetectorRef.markForCheck();
        });
        userSettingsService.obsUpdateHeader$.subscribe((params) => {
            this.updateHeader(params);
            this.changeDetectorRef.markForCheck();
        });
        //sessionStorage.removeItem('authToken');
    }

    ngOnInit() {
        let qs = this.queryParameters();
        if (qs?.["dialog"]) {
            this.haveHeaderFooter = false;
        }

        this.userSettingsService.obsOnSetLocale$.pipe(take(1)).subscribe(() => {
            this.initCommonTexts();
            this.changeDetectorRef.markForCheck();
        });

        this.initLocale();

        let code;
        if (qs) {
            code = qs["code"];
        }

        if (!this.cookieService.get("loginAs") && !code && !this.haveToken()) {
            this.haveHeaderFooter = false;
            return;
        }

        //force logout on already opened tabs
        window.addEventListener("storage", (event) => {
            if (event.key == "logout-event") {
                window.location.href = "/homepages/login";
            }
        });

        this.pageService.getPage(false, code).subscribe({
            next: (response) => {
                if (response) {
                    if (response.locale && (!sessionStorage.getItem("locale") || sessionStorage.getItem("locale") != response.locale)) {
                        sessionStorage.setItem("locale", response.locale);
                        // console.log("set locale from HTTP repsonse", response.locale);
                        this.userSettingsService.locale = response.locale;
                        this.translationService.setCurrentLanguage(this.userSettingsService.language).subscribe(() => this.initCommonTexts());
                    }

                    this.userSettingsService.timezone = response.timezone;
                    sessionStorage.setItem("timezone", response.timezone);
                    this.userSettingsService.timeoffset = response.timeoffset;

                    // this.cookieService.set("X-CSRF-Token", response.token, null, "/", null);

                    //console.debug(window.location.pathname);
                    if (!this.haveHeaderFooter /* || this.pageService.isPageWithoutHeaderFooter(window.location.pathname)*/) {
                        return;
                    }

                    //let qs = this.queryParameters();
                    //if (qs?.["dialog"]) {
                    // if (this.activatedRoute.snapshot.queryParams.dialog) {
                    //     this.haveHeaderFooter = false;
                    // }

                    if (response.decimals != null) {
                        this.userSettingsService.decimals = response.decimals;
                    }

                    this.chat = response.chat;
                    if (response.header?.eventStatus && typeof response.header.eventStatus.chat != "undefined") {
                        this.chat = response.header.eventStatus.chat;
                    }

                    this.changeDetectorRef.markForCheck();
                    //console.debug(response.menuHelper);
                    //this.userSettingsService.menuHelper = response.menuHelper;
                }

                this.subscribeRouterEvents();

                this.initCheckActivity();
            },
            error: (error) => {
                this.errorMsg = error.statusText;
                this.loader = false;
                this.changeDetectorRef.markForCheck();
                // this.router.navigate(["error"]);
                // console.debug(error);
                // console.debug(error.statusText);
            },
        });
    }

    private initLocale() {
        let params = this.routeParameters();
        let locale = sessionStorage.getItem("locale");
        if (params["locale"]) {
            locale = params["locale"];
            sessionStorage.setItem("locale", locale);
        }
        if (locale) {
            this.userSettingsService.locale = locale;
        }
    }

    private subscribeRouterEvents() {
        this.router.events.subscribe((event) => {
            // console.log(event);
            if (event instanceof NavigationEnd) {
                let activeUrl = event.url;
                if (activeUrl.includes(";")) {
                    //if url includes matrix param(s)
                    activeUrl = urlPath(event.url);
                }
                if (this.previousUrl != activeUrl) {
                    //show loader only if is a different component loaded
                    this.loaderService.initLoader();
                }
                this.previousUrl = activeUrl;

                if (this.header) {
                    this.header.changeMenu();
                }
            }
        });
    }

    private initCheckActivity() {
        this.pageService.checkActivity().subscribe();
        setInterval(() => {
            return this.pageService.checkActivity().subscribe((response) => {
                //console.debug(response);
            });
        }, 60000);
    }

    private haveToken(): boolean {
        const regex = /;token=([^;|?]*)/;
        return regex.exec(window.location.href)?.length > 0;
    }

    queryParameters() {
        let paramsBlock = window.location.href.split("?")[1];
        if (paramsBlock) {
            let paramsMap = paramsBlock.split("&").reduce((p, c) => {
                let components = c.split("=");
                p[components[0]] = components[1];
                return p;
            }, new Map<string, string>());
            return paramsMap;
        }
    }

    routeParameters() {
        let params = window.location.href.split(";").splice(1);
        return params.reduce((p, c) => {
            let components = c.split("=");
            p[components[0]] = components[1];
            return p;
        }, new Map<string, string>());
    }

    private initCommonTexts() {
        this.translationService.loadTranslations().subscribe(() => {
            let translations = this.translationService.getTranslations();
            if (this.setTitle) {
                this.titleService.setTitle(translations["general.app_title"]);
            }
            this.commonTextsService.setTextsByLanguage(translations);
            this.validationTextsService.setTextsByLanguage(translations);
        });
    }

    changeLanguage(language: Language) {
        sessionStorage.setItem("locale", language.locale);
        this.pageService.changeLanguage(language.key).subscribe((response) => {
            window.location.reload();
        });
    }

    chooseHistory(key: number) {
        this.pageService.chooseHistory(key).subscribe((response) => {
            if (response) {
                if (response.posts.length > 0) {
                    this.header.historyComponent.url = response.url;
                    this.header.historyComponent.posts = response.posts;
                    this.header.historyComponent.submit = true;
                } else {
                    window.location.href = response.url;
                }
            }
        });
    }

    updateHeader(params: UpdateHeaderParams) {
        if (this.header) {
            switch (params.eventType) {
                case UpdateHeaderEventType.WS_ERROR:
                    this.updateHeaderOnWsError(params.data);
                    break;
                case UpdateHeaderEventType.OPEN_CONTRACT:
                    this.updateHeaderOnOpenContract(params.data);
                    break;
                case UpdateHeaderEventType.AJAX_ERROR:
                    this.updateHeaderOnAjaxError(params.data);
                    break;
                case UpdateHeaderEventType.OPEN_EVENT:
                    this.updateHeaderOnOpenEvent(params.data);
                    break;
                case UpdateHeaderEventType.OPEN_ORDER:
                    this.updateHeaderOnOpenOrder(params.data);
                    break;
            }
        }
    }

    updateHeaderOnWsError(data) {
        this.header.wsError = data;
    }

    updateHeaderOnOpenContract(data) {
        // console.log(data);
        this.header.setContract(data);
    }

    updateHeaderOnAjaxError(data) {
        this.header.ajaxError = data;
    }

    updateHeaderOnOpenEvent(data) {
        // console.log('updateHeaderOnOpenEvent');
        // console.log(data);
        if (data.close) {
            //close opened event
            this.header.event = null;
            this.header.role = null;
            this.header.closeTime = null;
            this.header.evStatus = null;
            this.header.evStatusColor = null;
        }

        if (data.event) {
            this.header.setEvent(data.idEvent, data.event, data.role, data.type);
            // this.header.event = data.event;
            // this.header.role = data.role;
            this.idEvent = data.idEvent;
            this.header.setContract(null);
        }

        if (typeof data.chat != "undefined") {
            this.chat = data.chat;
        }

        if (data.closeTime) {
            //this.header.closeTime = new Date(event.close_time);
            this.header.closeTime = data.closeTime;
        } else {
            this.header.closeTime = null;
        }
        //this.header.evStatus = event.ev_status_txt;
        //this.header.evStatusColor = event.ev_status_color;
        this.header.evStatus = data.evStatus;
        this.header.evStatusColor = data.evStatusColor;

        this.changeDetectorRef.markForCheck();
    }

    updateHeaderOnOpenOrder(data: ResponseReqOrder) {
        this.header.req_order = data;
        this.changeDetectorRef.markForCheck();
    }
}
