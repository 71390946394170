import { Injectable } from "@angular/core";
import { UUID } from "@app/common/custom-types";

export enum EventType {
    EVENT_TYPE_NEG = 1,
    EVENT_TYPE_EVAL = 2,
    EVENT_TYPE_PLAN = 3
}

@Injectable({
    providedIn: "root",
})
export class HeaderService {
    private idEvent: UUID;
    private eventType: EventType;
    private eventName: string;
    constructor() {
        const event = JSON.parse(sessionStorage.getItem("event"));
        if (event) {
            this.idEvent = event.idEvent;
            this.eventType = event.eventType;
            this.eventName = event.eventName;
        }
    }

    public setEvent(idEvent: UUID, eventType: EventType, eventName: string): void {
        this.idEvent = idEvent;
        this.eventType = eventType;
        sessionStorage.setItem("event", JSON.stringify({ idEvent: idEvent, eventType: eventType, eventName: eventName }));
    }

    public getIdEvent() {
        return this.idEvent;
    }

    public getEventType() {
        return this.eventType;
    }

    public getEventName(): string {
        return this.eventName;
    }
}
